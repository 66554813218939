<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>
                    New supply order

                    
                </h3>

                
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-3 position-relative">
                    
                </div>
                <div class="col-md-6">

                </div>
                <div class="col-md-2 text-right">
                    
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td>Item</td>
                    <td>Qty</td>
                    <td>Vendor</td>
                    <td>Notes</td>
                </thead>
    
                <tbody>
                    <tr *ngFor="let row of newOrderBody; let i = index">
                        
                        <td> <input type="text" class="form-control" [(ngModel)]="row.item"/> </td>
                        <td> <input type="number" class="form-control" [(ngModel)]="row.qty"/> </td>
                        <td> <input type="text" class="form-control" [(ngModel)]="row.vendor"/> </td>
                        <td> <input type="text" class="form-control" [(ngModel)]="row.notes"/> </td>
    
                    </tr>

                    <tr class="text-right">
                        <td colspan="4">
                            <button *ngIf="!loading" class="btn btn-primary" (click)="submitOrder()">Place order</button>
                            <span class="spinner" *ngIf="loading">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                            </span>
                        </td>
                    </tr>
                    <!--
                    <tr class="text-right">
                        <td colspan="4">
                            <button class="btn btn-outline-dark" (click)="addOrderRow()">+</button>
                        </td>
                    </tr> -->
                </tbody>
    
            </table>
            
            <hr/>

            <h5>Previous Data</h5>
            <table class="table table-bordered">
                <thead>
                    <td>Vendor</td>
                    <td>Qty</td>
                    <td>Unit price</td>
                    <td>Total price</td>
                    <td>Added date</td>
                </thead>
    
                <tbody>
                    <tr *ngFor="let data of previousData; let i = index">
                        
                        <td>
                            <a href="javascript:void(0);" (click)="selectVendor(data)" title="Select this vendor">{{data.vendor}}</a>
                        </td>
                        <td>{{data.qty}}</td>
                        <td>{{data.unit_price}}</td>
                        <td>{{data.total_price}}</td>
                        <td>{{data.added_on}}</td>
    
                    </tr>

                    <!--
                    <tr class="text-right">
                        <td colspan="4">
                            <button class="btn btn-outline-dark" (click)="addOrderRow()">+</button>
                        </td>
                    </tr> -->
                </tbody>
    
            </table>
            

        </div>

    </div>

</div>


