<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Users ({{data.count}})</h3>
            </div>
        </div>

        <div class="page-body">
            

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-4">
                    
                </div>
                <div class="col-md-6">

                </div>
                <div class="col-md-2 text-right">
                    <button *ngIf="data.is_admin" data-target="#modalAdd" data-toggle="modal" class="btn btn-outline-dark">Add new</button>
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td>Name</td>
                    <td>Username</td>
                    <td>User role</td>
                    
                    <td></td>
                </thead>
                <tbody>
                    <tr *ngFor="let row of data.list">
                        <td>{{row.name}}</td>
                        <td>{{row.user_name}}</td>
                        <td>{{row.role}}</td>
                        <td>
                            <ng-container *ngIf="data.is_admin || data.my_id == row.id">
                                <a href="#modalEdit" data-toggle="modal" (click)="selectUser(row)">Edit user</a>

                                <ng-container *ngIf="data.is_admin">
                                    &nbsp; | &nbsp;
                                    <a href="javascript:void(0);" (click)="deleteUser(row)">Delete user</a>
                                </ng-container>
                            </ng-container>
                        </td>
                    </tr>
 
                </tbody>
                
            </table>

            

        </div>

    </div>

</div>


<!-- modals -->

<div class="modal fade" id="modalAdd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add new user</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fnew="ngForm" (ngSubmit)="submitUser(fnew.value)" id="formNew" ngNativeValidate>
              
            <div class="form-group">
                <label class="control-label col-md-12">Name</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Username/Email</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="user_name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Role</label>
                <div class="col-md-12">
                    <select class="form-control" ngModel name="role" required>
                        <option *ngFor="let role of data.roles" value="{{role.id}}"> {{role.name}}  </option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Password</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="password" class="form-control" minlength="6" required/>
                </div>
            </div>

            
            

            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNew" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<div class="modal fade" id="modalEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit user</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fedit="ngForm" (ngSubmit)="editUser(fedit.value)" id="formEdit" ngNativeValidate>
              
            <div class="form-group">
                <label class="control-label col-md-12">Name</label>
                <div class="col-md-12">
                    <input type="text" [(ngModel)]="selectedUser.name" name="name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Username/Email</label>
                <div class="col-md-12">
                    <input type="text" [(ngModel)]="selectedUser.user_name" name="user_name" class="form-control" required/>
                </div>
            </div>

            <div class="form-group" *ngIf="data.is_admin">
                <label class="control-label col-md-12">Role</label>
                <div class="col-md-12">
                    <select class="form-control" [(ngModel)]="selectedUser.role_id" name="role" required>
                        <option *ngFor="let role of data.roles" value="{{role.id}}"> {{role.name}}  </option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-md-12">Password (only if you need to change the password)</label>
                <div class="col-md-12">
                    <input type="text" ngModel name="password" class="form-control" minlength="6"/>
                </div>
            </div>

            
            

            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formEdit" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>