import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  data:any = {list:[]};
  search:any = "";
  offset:any = 0;
  loading:any = false;
  limit:any = 30;
  date:any = "";
  vendors:any = [];
  vendor:any = "";
  selectedOrderVendor:any = {}
  vendorFilter:any = "";

  selectedOrderBody:any = [{
    item:"",
    quantity:"",
    notes:""
  }];
  newOrderBody:any = [
    {
      item:"",
      qty:1,
      notes:""
    }
  ]

  allSelected:any = 1;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.loadData();
    this.loadVendors();
  }

  loadData(){
    this.dataService.getOrders(this.offset, this.date, this.vendorFilter).subscribe( (data: any )=> {
      this.data = data;
      
    } );
  }
  loadVendors(){
    this.dataService.getOrderVendors().subscribe( (data: any )=> {
      if(data.status == 0){
        this.vendors = data.vendors;
      }
      
    } );
  }

  

  addOrderRow(){
    this.newOrderBody.push({
      item:"",
      qty:1,
      notes:""
    });
  }

  submitOrder(){
    let data = {vendor:this.vendor, body:this.newOrderBody} ;

    this.loading = true;
    this.dataService.postOrderBody(data).subscribe( (res:any) => {
      this.loading = false;

      if(res.status == 0) {
        this.loadData();

        this.newOrderBody = [
          {
            item:"",
            qty:1,
            vendor:"",
            notes:""
          }
        ];

        //@ts-ignore
        $("#modalNew").modal("hide");
      } else {
        alert(res.msg);
      }

    });

  }

  viewOrder(row){
    this.dataService.getOrder(row.id).subscribe( (data: any )=> {
      if(data.status == 0){
        this.selectedOrderBody = data.data;
        this.selectedOrderVendor = data.vendor;
      }
      
    } );
  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadData();

  }

  filterByVendor(vendor){
    this.vendorFilter = vendor;
    this.loadData();
  }

  print(){
    //@ts-ignore
    let doc = new jsPDF();
    let y = 10;

    doc.setFontSize(10);

    doc.text(10, y, "Vendor: "+ this.vendorFilter);
    y += 5;
    doc.text(10, y, "Date: "+ this.getToday());
    y += 10;

    doc.text(10, y, "Kindly supply the following items to the designated location as per our inventory requirements.");
    y += 5;
    doc.text(10, y, "Your swift response in delivering these items is greatly appreciated.");
    y += 15;
    doc.setFontType("bold");
    doc.text(10, y, "Sl No.");
    doc.text(30, y, "Item");
    doc.text(90, y, "Quantity");
    doc.text(115, y, "Unit price");
    doc.text(140, y, "Notes");

    doc.setFontType("normal");

    //Iterate through orders;
    let i = 1;
    this.data.list.forEach(order => {

        if(order.selected){
          order.body.forEach(item => {

            let unit_price = "";
            unit_price = (item.unit_price != null ) ? item.unit_price.toString() : "";

            y += 10;
            doc.text(10, y, i.toString());
            doc.text(30, y, item.item);
            doc.text(90, y, item.quantity.toString());
            doc.text(115, y, unit_price);
            doc.text(140, y, item.notes);
  
            
            i++;
  
          });
        }

        
    });




    //output
    var string = doc.output('datauristring');
    var newWindow = window.open("", "PDF", 'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800');
    newWindow.document.write(
    '<html><head><title> - Orders </title></head><body><center>' +
    
    '</center><br>' +
    '<object width=100% height=100% type="application/pdf" data="'+string+'">' +
    '<embed type="application/pdf" src="'+string+'" id="embed_pdf"></embed>' +
    '</object></body></html>');
    newWindow.window.focus();

  }

  selectOrder(order){
    order.selected = true;
    this.checkSelectionStatus();
  }
  unselectOrder(order){
    order.selected = false;
    this.checkSelectionStatus();
  }

  setSelection(val){
    this.allSelected = val;

    if(val == 1) {
      this.data.list.forEach(order => {
        this.selectOrder(order);
      });
    } else if(val == 0){
      this.data.list.forEach(order => {
        this.unselectOrder(order);
      });
    }

  }

  checkSelectionStatus(){
    let status = 0;
    let checked = 0;

    this.data.list.forEach(order => {
      if(order.selected){
        checked++;
      }
    });

    console.log("checked", checked);
    console.log("count", this.data.list.length)
    if(checked == this.data.list.length){
      this.allSelected = 1;
    } else if(checked == 0){
      this.allSelected = 0;
    } else {
      this.allSelected = 2;
    }

  }

  getToday(){
    // Create a new Date object
    const today = new Date();

    // Get the day, month, and year from the Date object
    const day = today.getDate();
    const month = today.getMonth() + 1; // Months are zero-based
    const year = today.getFullYear();

    // Format the date as desired (e.g., MM/DD/YYYY)
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

}
