import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router) { }
  key:any = null;
  role_id:any = 0;
  loading:any = false;
  ngOnInit(): void {

    this.key = localStorage.getItem('key');
    this.role_id = localStorage.getItem('role_id');

    if (this.key !== null) {
      //Redirect to home;
      if(this.role_id == 20){
        this.router.navigate(['/dashboard/store-requests']);
      } else {
        this.router.navigate(['/dashboard/inventory']);
      }
      
    }

  }

  login(data){
    this.loading = true;
    this.dataService.login(data).subscribe( (res:any) => {
      this.loading = false;
      console.log(res);
      if(res.status == 0) {
        localStorage.setItem( "key", res.key);
        localStorage.setItem("role_id", res.role_id);

        if (localStorage.getItem('key') == res.key ) {
          if(res.role_id == 20){
            this.router.navigate(['/dashboard/store-requests']);
          } else {
            this.router.navigate(['/dashboard/inventory']);
          }
        }

      } else {
        alert(res.msg);
      }

    });

  }

}
