<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>
                   Reports

                    
                </h3>

                
            </div>
        </div>

        <div class="page-body">

           <div class="row">
                <div class="col-md-6">
                    <div class="report">
                        <h5>Assets Report</h5>
                        <p class="report-description">Assets added between a date range</p>
                        <form #fassets="ngForm" (ngSubmit)="submitAssets(fassets.value)" id="formAssets" ngNativeValidate>
                        <div class="row" style="margin-bottom: 10px;">
                            <div class="col-md-6">
                                <label class="control-label">Date 1</label>
                                <input type="date" class="form-control" ngModel name="date1" required/>
                            </div>
                            <div class="col-md-6">
                                <label class="control-label">Date 2</label>
                                <input type="date" class="form-control" ngModel name="date2" required/>
                            </div>
                        </div>
                        </form>
                        <button form="formAssets" class="btn btn-primary">Download report</button>
                    </div>

                </div>

                <div class="col-md-6">
                    <div class="report">
                        <h5>Stock Report</h5>
                        <p class="report-description">Stock added to inventory between a date range</p>
                        <form #fstock="ngForm" (ngSubmit)="submitStock(fstock.value)" id="formStock" ngNativeValidate>
                        <div class="row" style="margin-bottom: 10px;">
                            <div class="col-md-6">
                                <label class="control-label">Date 1</label>
                                <input type="date" class="form-control" ngModel name="date1" required/>
                            </div>
                            <div class="col-md-6">
                                <label class="control-label">Date 2</label>
                                <input type="date" class="form-control" ngModel name="date2" required/>
                            </div>
                        </div>
                        </form>
                        <button form="formStock" class="btn btn-primary">Download report</button>
                    </div>

                </div>

                <div class="col-md-6">
                    <div class="report">
                        <h5>Stock Dispense Report</h5>
                        <p class="report-description">Stock dispensed between a date range</p>
                        <form #fsd="ngForm" (ngSubmit)="submitSd(fsd.value)" id="formSd" ngNativeValidate>
                        <div class="row" style="margin-bottom: 10px;">
                            <div class="col-md-6">
                                <label class="control-label">Date 1</label>
                                <input type="date" class="form-control" ngModel name="date1" required/>
                            </div>
                            <div class="col-md-6">
                                <label class="control-label">Date 2</label>
                                <input type="date" class="form-control" ngModel name="date2" required/>
                            </div>
                        </div>
                        </form>
                        <button form="formSd" class="btn btn-primary">Download report</button>
                    </div>

                </div>

                
           </div> 

        </div>

    </div>

</div>


