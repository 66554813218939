import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-store-requests',
  templateUrl: './store-requests.component.html',
  styleUrls: ['./store-requests.component.scss']
})
export class StoreRequestsComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  data:any = {status:0, data:[], count:0};
  loadingPhoto:any = false;
  offset:any = 0;
  limit:any = 6;
  issuing:any = false;

  ngOnInit(): void {
    this.loadRequests();
  }

  loadRequests(){
    this.dataService.getStorekeeperRequests(this.offset, this.limit).subscribe( (data: any )=> {
      if(data.status == 0){
        this.data = data;
        this.loadPhotos();
      } else {
        alert(data.msg);
      }
    } );
  }

  loadPhotos(){
    this.data.data.forEach(item => {
      if(item.photo_key != null){

        item.loadingPhoto = true;
        this.dataService.getAssetPhoto(item.photo_key).subscribe( (data: any )=> {
          item.loadingPhoto = false;
    
          if(data.status == 0){
            item.photo_url = data.url;
            
          }
          
        } );
  
      }
    });
  }

  issueItem(item){
    var cnf = confirm("സ്ഥിരീകരിക്കുക");

    if(cnf){
      let data = {id:item.id}
      item.issuing = true;
      this.dataService.postIssueStock(data).subscribe( (res:any) => {
        
        item.issuing = false;
        if(res.status == 0) {
          this.loadRequests();
          
          
        } else {
          alert(res.msg);
        }
  
      });
    }

  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadRequests();

  }

}
