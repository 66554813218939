<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Inventory ({{data.count}})</h3>
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-4 position-relative">
                    <form #fsearch="ngForm" (ngSubmit)="searchStock(fsearch.value)" id="formSearch" ngNativeValidate>
                        <input type="text" class="form-control" placeholder="Search by name, common name, bil no. or item code" [(ngModel)]="search" name="search"/>
                        <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                    </form>
                </div>
                <div class="col-md-4">

                </div>
                <div class="col-md-4 text-right">
                    <button *ngIf="data.is_admin" data-target="#modalAdd" data-toggle="modal" class="btn btn-outline-dark">Add new</button> &nbsp;
                    <button *ngIf="data.is_admin" routerLink="/dashboard/low-stock" class="btn btn-outline-dark">View low stock</button>
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td>Bill No.</td>
                    <td>Item name</td>
                    <td>Common name</td>
                    <td>Vendor</td>
                    <td>Qty</td>
                    <td>Unit of measurement</td>
                    <td>Unit price</td>
                    <td>Total price</td>
                    <td>Dispensed</td>
                    <td>Balance</td>
                    <td>Added on</td>
                    <td>Added By</td>
                    <td></td>
                </thead>
                <tbody>
                    <tr *ngFor="let stock of data.list">
                        <td>{{stock.bill_no}}</td>
                        <td>{{stock.stock}}</td>
                        <td>{{stock.common_name}}</td>
                        <td>{{stock.company}}</td>
                        <td>{{stock.qty}}</td>
                        <td>{{stock.unit}}</td>
                        <td>{{stock.unit_price}}</td>
                        <td>{{stock.total_price}}</td>
                        <td>{{stock.dispensed}}</td>
                        <td>{{stock.balance}}</td> 
                        <td>{{stock.added_on | date}}</td>
                        <td>{{stock.added_by}}</td>
                        <td>
                            <a *ngIf="role_id == 21" href="#modalEdit" data-toggle="modal" (click)="selectStock(stock)">View/Edit</a> 
                            <a *ngIf="role_id == 20" href="#modalEdit" data-toggle="modal" (click)="selectStock(stock)">View</a> 
                            &nbsp; | &nbsp;
                            
                            
                            <a *ngIf="role_id == 21" href="javascript:void(0);" (click)="deleteStock(stock)">Delete</a> 
                             <br/>
                            <a href="javascript:void(0);" routerLink="/dashboard/dispense-log/{{stock.id}}">View Log</a> &nbsp; | &nbsp;
                            <a href="#modalPhoto" data-toggle="modal" (click)="viewPhoto(stock)">Photo</a>
                            
                        </td>
                        
                    </tr>
                </tbody>
                
            </table>

            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>

        </div>

    </div>

</div>


<!-- modals -->

<!-- add stock -->
<div class="modal fade" id="modalAdd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add new stock</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fnew="ngForm" (ngSubmit)="submitStock(fnew.value)" id="formNew" ngNativeValidate>
            <div class="alert alert-primary" role="alert" *ngIf="newItem.stock.length > 0">
                Remaining stock for this item: {{newItem.balance}}
              </div>
              
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Item code</label>
                        <div class="col-md-12">
                            <input type="text" ngModel name="item_code" class="form-control" (blur)="fetchItemWithCode($event.target.value)" required/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Bill No.</label>
                        <div class="col-md-12">
                            <input type="text" ngModel name="bill_no" class="form-control" required/>
                        </div>
                    </div>
                </div>
                
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Item name</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newItem.stock" name="stock" class="form-control" required/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Common name</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newItem.common_name" name="common_name" class="form-control" required/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Vendor</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newItem.vendor" name="company" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Brand name</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newItem.brand" name="brand" class="form-control"/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Specification</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newItem.specification" name="specification" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Unit of measurement</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newItem.unit" name="unit" class="form-control"/>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Quantity</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newQty" name="qty" (input)="calcTp()" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Unit price</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="newUp" name="unit_price" (input)="calcTp()" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Total price</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="newTp" name="total_price" class="form-control"/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Date</label>
                        <div class="col-md-12">
                            <input type="date" ngModel name="added_on" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Remarks</label>
                        <div class="col-md-12">
                            <input type="text" ngModel name="remarks" class="form-control"/>
                        </div>
                    </div>
                </div>
            </div>

            
            
            

            

            

            
            

            

            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNew" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>


<!-- edit stock -->
<div class="modal fade" id="modalEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit stock</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #fedit="ngForm" (ngSubmit)="editStock()" id="formEdit" ngNativeValidate>
            
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Bill No.</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.bill_no" name="bill_no" class="form-control" required/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Item code</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.item_code" name="item_code" class="form-control" required/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Item name</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.stock" name="stock" class="form-control" required/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Common name</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.common_name" name="common_name" class="form-control" required/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Vendor</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.company" name="company" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Brand name</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.brand" name="brand" class="form-control"/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Specification</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.specification" name="specification" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Unit of measurement</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.unit" name="unit" class="form-control"/>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Quantity</label>
                        <div class="col-md-12">
                            <input type="number" [(ngModel)]="selectedStock.qty" name="qty" (input)="calcTp()" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Unit price</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedStock.unit_price" name="unit_price" (input)="calcTp()" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Total price</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.total_price" name="total_price" class="form-control"/>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Date</label>
                        <div class="col-md-12">
                            <input type="date" [(ngModel)]="selectedStock.added_on" name="added_on" class="form-control"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label col-md-12">Remarks</label>
                        <div class="col-md-12">
                            <input type="text" [(ngModel)]="selectedStock.remarks" name="remarks" class="form-control"/>
                        </div>
                    </div>
                </div>
            </div>
            

          </form>
        </div>
        <div class="modal-footer" *ngIf="role_id == 21">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formEdit" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

  

<div class="modal fade" id="modalPhoto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{selectedStock.stock}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <ng-container *ngIf="selectedStock.photo_url != null">
                <p class="text-center img-loader" *ngIf="loadingPhoto">
                    <i class="fa-solid fa-spinner fa-spin"></i>
                </p>
    
                <p class="text-center" *ngIf="!loadingPhoto">
                    <img [src]="imgUrl" class="img-fluid"/>
                </p>

                <form #fphoto="ngForm" (ngSubmit)="uploadPhoto()" id="formPhoto" ngNativeValidate>
                    <div class="row" style="margin-bottom: 20px">
                
                        <div class="col-md-12 text-center">
                            <label class="control-label col-md-12">Change photo</label>
                            <div class="col-md-6 offset-md-3">
                                <input id="fileInput" type="file" class="form-control" (change)="onFileSelect($event)" accept=".jpg, .jpeg, .png, .gif, .webp" required/>
                                <p class="text-center size-warning" *ngIf="uploadSize != ''">
                                    File size is {{uploadSize}}. 
                                    <ng-container *ngIf="uploadFile.size >= 500000">
                                      You can't upload files greater than 500KB
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row" >
                
                    <div class="col-md-12 text-center" style="margin-left: 20px">
                        <button *ngIf="!uploading" class="btn btn-primary" form="formPhoto">Upload</button>
                        <span class="spinner" *ngIf="uploading">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </span>
                    </div>
                </div>

            </ng-container>
            

            <ng-container *ngIf="selectedStock.photo_url == null">

                <form #fphoto="ngForm" (ngSubmit)="uploadPhoto()" id="formPhoto" ngNativeValidate>
                    <div class="row" style="margin-bottom: 20px">
                
                        <div class="col-md-6">
                            <label class="control-label col-md-12">Upload photo</label>
                            <div class="col-md-12">
                                <input id="fileInput" type="file" class="form-control" (change)="onFileSelect($event)" accept=".jpg, .jpeg, .png, .gif, .webp" required/>
                                <p class="text-center size-warning" *ngIf="uploadSize != ''">
                                    File size is {{uploadSize}}. 
                                    <ng-container *ngIf="uploadFile.size >= 500000">
                                      You can't upload files greater than 500KB
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row" >
                
                    <div class="col-md-6" style="margin-left: 20px">
                        <button *ngIf="!uploading" class="btn btn-primary" form="formPhoto">Upload</button>
                        <span class="spinner" *ngIf="uploading">
                            <i class="fa-solid fa-spinner fa-spin"></i>
                          </span>
                    </div>
                </div>
                

            </ng-container>

        </div>
        
      </div>
    </div>
</div>