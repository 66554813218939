<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Assets ({{data.count}})</h3>
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-6 position-relative">
                    <form #fsearch="ngForm" (ngSubmit)="searchAsset(fsearch.value)" id="formSearch" ngNativeValidate>
                        <input type="text" class="form-control" placeholder="Type and press enter to search (name. description, location, bill no.)" [(ngModel)]="search" name="search"/>
                        <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                    </form>
                </div>
                <div class="col-md-4">

                </div>
                <div class="col-md-2 text-right">
                    <button data-target="#modalAdd" data-toggle="modal" class="btn btn-outline-dark">Add new</button>
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td>Asset</td>
                    <td>Description</td>
                    <td>Unit of measurement</td>
                    <td>Current location</td>
                    <td>Notes</td>
                    <td>Photo</td>
                    
                    <td></td>
                </thead>
                <tbody>

                    <tr *ngFor="let item of data.list">
                       <td>{{item.name}}</td>
                       <td>{{item.description}}</td>
                       <td>{{item.unit}}</td>
                       <td>{{item.location}}</td>
                       <td>{{item.notes}}</td>
                       <td>
                           <ng-container *ngIf="item.photo_url != null">
                               <a href="#modalPhoto" data-toggle="modal" (click)="viewAssetPhoto(item)">View photo</a>
                           </ng-container>

                           <ng-container *ngIf="item.photo_url == null">
                           <p class="text-muted">No photo found</p> 
                        </ng-container>
                       </td>
                       <td>
                        <a href="#modalEdit" data-toggle="modal" (click)="selectAsset(item)">View & Edit</a> 
                            &nbsp; | &nbsp;
                        <a href="javascript:void(0);" (click)="deleteAsset(item)">Delete</a> 
                        &nbsp; | &nbsp;
                        <a href="#modalLocations" data-toggle="modal" (click)="loadLocations(item)">Asset movement register</a> 
                       </td>
                    </tr>
                    
                </tbody>
                
            </table>

            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>

        </div>

    </div>

</div>


<!-- modals -->

<!-- add asset-->
<div class="modal fade" id="modalAdd" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add new stock</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fnew="ngForm" (ngSubmit)="submitAsset(fnew.value)" id="formNew" ngNativeValidate>
              
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Asset name</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="name" class="form-control" required/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Description</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="description" class="form-control"/>
                    </div>
                </div>
            </div>
                
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Unit of measurement</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="unit" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Qty</label>
                    <div class="col-md-12">
                        <input type="number" [(ngModel)]="assetQty" name="qty" (input)="populateAssetNo()" autocomplete="off" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Asset number</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="number" class="form-control"/>
                        <small *ngIf="assetNo != null">{{assetNo}}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Asset vendor</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="vendor" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Notes</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="notes" class="form-control" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Bill No.</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="bill_no" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Bill Amount</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="bill_amount" class="form-control"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Date</label>
                    <div class="col-md-12">
                        <input type="date" ngModel name="bill_date" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Current location</label>
                    <div class="col-md-12">
                        <input type="text" ngModel name="location" class="form-control"/>
                    </div>
                </div>
               
                
            </div>

            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Image (optional)</label>
                    <div class="col-md-12">
                        <input id="fileInput" type="file" class="form-control" (change)="onFileSelect($event)" accept=".jpg, .jpeg, .png, .gif, .webp"/>
                    </div>
                </div>
            </div>
            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formNew" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<!-- edit asset-->
<div class="modal fade" id="modalEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit asset</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #fedit="ngForm" (ngSubmit)="editAsset(fedit.value)" id="formEdit" ngNativeValidate>
              
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Asset name</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.name" name="name" class="form-control" required/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Description</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.description" name="description" class="form-control"/>
                    </div>
                </div>
            </div>
                
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Unit of measurement</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.unit" name="unit" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Qty</label>
                    <div class="col-md-12">
                        <input type="number" [(ngModel)]="selectedAsset.qty" name="qty" (input)="populateAssetNo(selectedAsset)" autocomplete="off" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Asset number</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.number" name="number" class="form-control"/>
                        <small *ngIf="assetNo != null">{{selectedAsset.assetNo}}</small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Asset vendor</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.vendor" name="vendor" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Notes</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.notes" name="notes" class="form-control" />
                    </div>
                </div>
               
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Bill No.</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.bill_no" name="bill_no" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Bill Amount</label>
                    <div class="col-md-12">
                        <input type="text" [(ngModel)]="selectedAsset.bill_amount" name="bill_amount" class="form-control"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="control-label col-md-12">Date</label>
                    <div class="col-md-12">
                        <input type="date" [(ngModel)]="selectedAsset.bill_date" name="bill_date" class="form-control"/>
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="control-label col-md-12">Image (optional)</label>
                    <div class="col-md-12">
                        <input id="fileInputEdit" type="file" class="form-control" (change)="onFileSelect($event)" accept=".jpg, .jpeg, .png, .gif, .webp"/>
                    </div>
                </div>
            </div>
            

            

          </form>
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" form="formEdit" class="btn btn-primary">Save</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<!-- view photo-->

<div class="modal fade" id="modalPhoto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{selectedAsset.name}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <p class="text-center img-loader" *ngIf="loadingPhoto">
                <i class="fa-solid fa-spinner fa-spin"></i>
            </p>

            <p class="text-center" *ngIf="!loadingPhoto">
                <img [src]="imgUrl" class="img-fluid"/>
            </p>

        </div>
        
      </div>
    </div>
</div>

<!-- locations -->
<div class="modal fade" id="modalLocations" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Asset movement register: {{selectedAsset.name}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <table class="table table-bordered">
                <thead>
                    <td>Location</td>
                    <td>Recorded by</td>
                    <td>Recorded at</td>
                </thead>
                <tbody>
                    <tr *ngFor="let row of assetLocations">
                        <td>{{row.location}}</td>
                        <td>{{row.recorded_by}}</td>
                        <td>{{row.recorded_at}}</td>
                    </tr>
                    
                </tbody>
            </table>

            <hr/>

            <h5>Move to new location</h5>
            
                <div class="row">
                    <div class="col-md-6">
                        <input type="text" [(ngModel)]="newLocation" name="location" class="form-control" placeholder="Enter new location"/>
                    </div>
                    <div class="col-md-2">
                        <button type="submit" class="btn btn-primary" (click)="submitAssetLocation()">Move</button>
                    </div>
                </div>
            
            

        </div>
        
      </div>
    </div>
</div>