import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {

  
  constructor(private dataService: DataService) { }
  data:any = {list:[]};
  search:any = "";
  offset:any = 0;
  loading:any = false;
  loadingPhoto:any = false;
  limit:any = 30;
  uploadFile:any;
  selectedAsset:any = {};
  imgUrl:any = null;
  assetLocations:any = [];
  newLocation:any = "";
  assetNo:any = "";
  assetQty:any = 1;

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getAssets(this.offset, this.search).subscribe( (data: any )=> {
      this.data = data;
      console.log(data);
      
    } );
  }

  clearSearch(){
    this.search = "";
    this.loadData();
  }

  searchAsset(data){
    this.search = data.search;
    this.offset = 0;
    this.loadData();
  }

  onFileSelect(e){
    this.uploadFile = e.target.files[0];

  }

  submitAsset(data){

    this.loading = true;
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("location", data.location);
    formData.append("notes", data.notes);
    formData.append("unit", data.unit);
    formData.append("number", data.number);
    formData.append("qty", data.qty);
    formData.append("vendor", data.vendor);
    formData.append("bill_no", data.bill_no);
    formData.append("bill_amount", data.bill_amount);
    formData.append("bill_date", data.bill_date);
    formData.append("location", data.location);

    if(typeof this.uploadFile !== undefined && this.uploadFile != null){
      formData.append('file', this.uploadFile, this.uploadFile.name);
    }
    
    this.dataService.postAsset(formData).subscribe( (res:any = {status:0, msg:""}) => {
      this.loading = false;
      if(res.status == 0) {
        
        //@ts-ignore
        $("#modalAdd").modal("hide");
        this.assetNo = "";
        this.loadData();
        this.uploadFile =  null;
        
        var input = document.getElementById('fileInput');
        input.setAttribute("type", "text");
        input.setAttribute("type", "file");
        
      } else {
        alert(res.msg);
      }
    
    });

  }

  editAsset(data){

    this.loading = true;
    const formData = new FormData();
    formData.append("id", this.selectedAsset.id);
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("location", data.location);
    formData.append("notes", data.notes);
    formData.append("unit", data.unit);
    formData.append("qty", data.qty);
    formData.append("number", data.number);
    formData.append("vendor", data.vendor);
    formData.append("bill_no", data.bill_no);
    formData.append("bill_amount", data.bill_amount);
    formData.append("bill_date", data.bill_date);

    if(typeof this.uploadFile !== undefined && this.uploadFile != null){
      formData.append('file', this.uploadFile, this.uploadFile.name);
    }
    
    this.dataService.postEditAsset(formData).subscribe( (res:any = {status:0, msg:""}) => {
      this.loading = false;
      if(res.status == 0) {
        
        //@ts-ignore
        $("#modalEdit").modal("hide");
        this.loadData();
        this.uploadFile =  null;
        
        var input = document.getElementById('fileInputEdit');
        input.setAttribute("type", "text");
        input.setAttribute("type", "file");
        
      } else {
        alert(res.msg);
      }
    
    });

  }

  viewAssetPhoto(item){
    this.selectedAsset = item;

    this.loadingPhoto = true;
    this.dataService.getAssetPhoto(item.photo_url).subscribe( (data: any )=> {
      this.loadingPhoto = false;

      if(data.status == 0){
        this.imgUrl= data.url;
        console.log(this.imgUrl);
      }
      
    } );


  }

  selectAsset(item){
    this.selectedAsset = item;
  }

  deleteAsset(item){

    var cnf = confirm("Do you really want to delete " + item.name + "?")

    if(cnf){
      let data = {id:item.id}

      this.dataService.postDeleteAsset(data).subscribe( (res:any = {status:0, msg:""}) => {
        this.loading = false;
        if(res.status == 0) {
          this.loadData();
          
        } else {
          alert(res.msg);
        }
      
      });        

    }

  }

  loadLocations(asset){
    this.selectedAsset = asset;
    this.dataService.getAssetLocations(asset.id).subscribe( (data: any )=> {
        this.assetLocations = data.list;
      
    } );
  }

  submitAssetLocation(){
    let data = {asset_id:this.selectedAsset.id, location:this.newLocation}

    this.dataService.postAssetLocation(data).subscribe( (res:any = {status:0, msg:""}) => {
      this.newLocation = "";
      if(res.status == 0) {
        this.loadLocations(this.selectedAsset);
        
      } else {
        alert(res.msg);
      }
    
    });     
  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadData();

  }

  populateAssetNo(asset=null){

    let assetNo = "";
    let assetQty = 1;

    if(asset != null){
      assetQty = asset.qty;
    } else {
      assetQty = this.assetQty;
    }

    if(assetQty > 1){
      assetNo = "1-" + assetQty.toString();
    } else {
      assetNo = "";
    }

    if(asset != null){
      asset.assetNo = assetNo;
    } else {
      this.assetNo = assetNo;
    }

    console.log(assetNo);

  }

}
