import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {status:0, list:[], count:0}
  loading:any = false;
  selectedUser:any = {};

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getUsers().subscribe( (data: any )=> {
      this.data = data;
      console.log(data);
      
    } );
  }

  submitUser(data){
    this.loading = true;
    
    
    this.dataService.postUser(data).subscribe( (res:any = {status:0, msg:""}) => {
      this.loading = false;

      if(res.status == 0){
        this.loadData();
        //@ts-ignore
        $("#modalAdd").modal("hide");
      } else {
        alert(res.msg);
      }

    }); 
  }

  selectUser(user){
    this.selectedUser = user;
  }

  editUser(data){
    this.loading = true;
    data.user_id = this.selectedUser.id;
    this.dataService.postEditUser(data).subscribe( (res:any = {status:0, msg:""}) => {
      this.loading = false;

      if(res.status == 0){
        this.loadData();
        //@ts-ignore
        $("#modalEdit").modal("hide");
      } else {
        alert(res.msg);
      }

    });
  }

  deleteUser(user){
    var cnf = confirm("Do you really want to delete " + user.name + "?" );

    if(cnf){
      let data = {user_id:user.id}
      this.dataService.postDeleteUser(data).subscribe( (res:any = {status:0, msg:""}) => {
        this.loading = false;
  
        if(res.status == 0){
          this.loadData();
          
        } else {
          alert(res.msg);
        }
  
      });
    }

    
  }

}


