<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Return Requests ({{data.count}})</h3>
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                
                <div class="col-md-4 position-relative">

                    <form #fsearch="ngForm" (ngSubmit)="searchByUser(fsearch.value)" id="formSearch" ngNativeValidate>
                        <small>Search by username</small>
                        <input type="text" class="form-control" placeholder="Type and press enter to search" [(ngModel)]="search" name="search"/>
                        <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                    </form>

                    <!--<small>Filter by request status</small>
                    <select class="form-control" (change)="filterByStatus($event.target.value)" [(ngModel)]="statusFilter">
                        <option value="0">View All</option>
                        <option value="1">Completed </option>
                        <option value="2">Pending</option>
                        <option value="3">Processed but pending user confirmation</option>
                    </select>-->
                </div>
                <div class="col-md-2 text-right">
                    
                </div>
                <div class="col-md-2 text-right">
                    
                </div>
            </div>

            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(statusFilter == 0) ? 'active' : ''" href="javascript:void(0);" (click)="filterByStatus(0)">Pending Approval</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(statusFilter == 1) ? 'active' : ''" href="javascript:void(0);" (click)="filterByStatus(1)">Approved</a>
                </li>
                
                
                
              </ul>

            <table class="table table-bordered">
                <thead>
                    <td>Item</td>
                    <td>Returned by</td>
                    <td>Return qty</td>
                    
                    <td></td>
                </thead>
                <tbody>

                    <tr *ngFor="let item of data.list">
                        <td>{{item.item}}</td>
                        <td>{{item.returned_by}}</td>
                        <td>{{item.return_qty}}</td>
                        
                        <td>
                            <a href="javascript:void(0);" routerLink="/dashboard/requests/{{item.request_id}}">View original request</a> &nbsp;&nbsp;
                            <a href="javascript:void(0);" (click)="acceptReturn(item)" *ngIf="statusFilter == 0">Accept return</a>
                        </td>
                    </tr>
                    
                </tbody>
                
            </table>

            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>

        </div>

    </div>

</div>


<!-- modals -->

