<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Requests ({{data.count}})</h3>
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-4">
                    <small>Filter by request date</small>
                    <input type="date" class="form-control" [(ngModel)]="date" (input)="filterDate($event.target.value)">
                    
                </div>
                <div class="col-md-4 position-relative">

                    <form #fsearch="ngForm" (ngSubmit)="searchByUser(fsearch.value)" id="formSearch" ngNativeValidate>
                        <small>Search by username</small>
                        <input type="text" class="form-control" placeholder="Type and press enter to search" [(ngModel)]="search" name="search"/>
                        <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                    </form>

                    <!--<small>Filter by request status</small>
                    <select class="form-control" (change)="filterByStatus($event.target.value)" [(ngModel)]="statusFilter">
                        <option value="0">View All</option>
                        <option value="1">Completed </option>
                        <option value="2">Pending</option>
                        <option value="3">Processed but pending user confirmation</option>
                    </select>-->
                </div>
                <div class="col-md-2 text-right">
                    
                </div>
                <div class="col-md-2 text-right">
                    
                </div>
            </div>

            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(statusFilter == 2) ? 'active' : ''" href="javascript:void(0);" (click)="filterByStatus(2)">Incomplete</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(statusFilter == 3) ? 'active' : ''" href="javascript:void(0);" (click)="filterByStatus(3)">Processed</a>
                </li>
                
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="(statusFilter == 1) ? 'active' : ''" href="javascript:void(0);" (click)="filterByStatus(1)">Completed</a>
                </li>
                
              </ul>

            <table class="table table-bordered">
                <thead>
                    <td>Requested by</td>
                    <td>User designation</td>
                    <td>User department</td>
                    <td>Approved by</td>
                    <td *ngIf="statusFilter == 2">Pending count</td>
                    <td>Requested on</td>
                    
                    
                    
                    <td></td>
                </thead>
                <tbody>

                    <tr *ngFor="let item of data.list">
                        <td> {{item.requested_by}} </td>
                        <td> {{item.designation}} </td>
                        <td> {{item.department}} </td>
                        <td> {{item.approved_by}} </td>
                        <td *ngIf="statusFilter == 2">
                            <ng-container *ngFor="let body of item.body">
                                {{body.item}}: {{body.qty- body.dispensed_qty}}
                                <br/>
                            </ng-container>
                        </td>
                        <td> {{item.requested_at}} </td>
                        
                        <td>
                            <a href="javascript:void(0);" routerLink="/dashboard/requests/{{item.id}}">View request</a>
                        </td>
                    </tr>
                    
                </tbody>
                
            </table>

            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>

        </div>

    </div>

</div>


<!-- modals -->

