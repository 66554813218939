<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>
                    Requests

                    
                </h3>

                
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-3 position-relative">
                    
                </div>
                <div class="col-md-6">

                </div>
                <div class="col-md-2 text-right">
                    
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    
                    <td>Item (ഐറ്റം)</td>
                    <td>Photo (ഫോട്ടോ)</td>
                    <td>Requested by</td>
                    <td>Quantity (എണ്ണം/അളവ്)</td>
                    <td>Unit (യൂണിറ്റ്)</td>
                    
                    <td></td>
                </thead>
                <tbody>

                    <tr *ngFor="let item of data.data; let i = index">
                        
                        <td> 
                            {{item.name}} 

                            <p class="remarks" *ngIf="item.remarks != null">
                               <em> Remarks: {{item.remarks}}</em>
                            </p>

                        </td>
                        <td class="photo-td">
                            <ng-container *ngIf="item.loadingPhoto">
                                <i class="fa-solid fa-spinner fa-spin"></i>
                            </ng-container> 
                            <ng-container *ngIf="!item.loadingPhoto">
                                <img [src]="item.photo_url" class="img-fluid"/>
                            </ng-container> 
                            

                        </td>
                        <td>{{item.requested_by}}</td>
                        <td> {{item.dispensed_qty}} </td>
                        <td> {{item.unit}} </td>
                        
                        <td>
                            <button *ngIf="!item.issuing" class="btn btn-lg btn-primary" (click)="issueItem(item)">OK</button>
                            <i *ngIf="item.issuing" class="fa-solid fa-spinner fa-spin"></i>
                        </td>
                    </tr>
                    
                    
                </tbody>
                
            </table>

            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>

            
            

        </div>

    </div>

</div>

<!-- modals -->

