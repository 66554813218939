import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor(private dataService: DataService) { }

  path:any = "";

  ngOnInit(): void {
    
    this.path = this.dataService.REST_API_SERVER;
  }

  submitAssets(data){
    
    let key = localStorage.getItem("key");
    
    let url = this.path + "reports/assets&date1="+data.date1+"&date2="+data.date2+"&key="+key;
    window.open(url);

  }

  submitStock(data){
    
    let key = localStorage.getItem("key");
    let url = this.path + "reports/stock&date1="+data.date1+"&date2="+data.date2+"&key="+key;
    window.open(url);

  }

  submitSd(data){
    
    let key = localStorage.getItem("key");
    let url = this.path + "reports/stock_dispenses&date1="+data.date1+"&date2="+data.date2+"&key="+key;
    window.open(url);

  }

}
