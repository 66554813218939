import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any = {list:[]};
  search:any = "";
  offset:any = 0;
  loading:any = false;
  limit:any = 30;
  date:any = "";
  statusFilter:any = 2;
  
  

  ngOnInit(): void {
    this.statusFilter = this.dataService.localData.statusFilter;
    this.search = this.dataService.localData.searchRequestedUser;
    this.date = this.dataService.localData.requestDateFilter;
    this.loadData();
  }

  loadData(){
    this.dataService.getRequests(this.offset, this.date, this.statusFilter, this.search).subscribe( (data: any )=> {
      this.data = data;
      
    } );
  }

  filterDate(date){
    this.date = date;
    this.dataService.localData.requestDateFilter = this.date;
    this.loadData();
  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadData();

  }

  filterByStatus(val){
    this.statusFilter = val;
    this.dataService.localData.statusFilter = this.statusFilter;
    this.loadData();
  }

  searchByUser(data){
    this.search = data.search;
    this.dataService.localData.searchRequestedUser = this.search;
    this.offset = 0;
    this.loadData();
  }

  clearSearch(){
    this.search = "";
    this.dataService.localData.searchRequestedUser = this.search;
    this.loadData();
  }

}
