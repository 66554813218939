<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>
                    Request from {{request.requested_by}} ({{request.request_date}})

                    
                </h3>

                <span class="badge" [ngClass]="request.statusClass">
                    {{request.status}}
                </span>
                
                
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-3 position-relative">
                    
                </div>
                <div class="col-md-6">

                </div>
                <div class="col-md-2 text-right">
                    
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td>Sl No.</td>
                    <td>Requested item</td>
                    <td>Requested quantity</td>
                    <td>Dispensed quantity</td>
                    <td></td>
                </thead>
                <tbody>

                    <tr *ngFor="let item of request.body; let i = index">
                        <td>{{i+1}}</td>
                        <td>
                            {{item.item}}
                            <ng-container *ngIf="item.photo_url != null">
                                &nbsp; | &nbsp;
                                <a href="#modalPhoto" data-toggle="modal" (click)="viewPhoto(item)">View Photo</a>
                            </ng-container>
                        </td>
                        <td>{{item.qty}}</td>
                        <td [ngClass]="(item.dispensed_qty < item.qty) ? 'incomplete' : 'complete'">{{item.dispensed_qty}}</td>
                        <td>
                            <button *ngIf="item.dispensed_qty < item.qty" class="btn btn-outline-dark" (click)="selectItem(item)" data-target="#modalProcessRequestItem" data-toggle="modal">
                                Process
                            </button>
                        </td>
                    </tr>
                    
                    
                </tbody>
                
            </table>

            <ng-container *ngIf="dispenses.list.length >0">
                <div class="row dispense-log">
                    <div class="col-md-12">
                        <h5>Dispense log ({{dispenses.list.length}})</h5>

                        <table class="table table-bordered">
                            <thead>
                                <td>Sl No.</td>
                                <td>Stock</td>
                                <td>Dispensed quantity</td>
                                <td>Dispensed by</td>
                                <td>Dispensed at</td>
                            </thead>
                            <tbody>
            
                                <tr *ngFor="let item of dispenses.list; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{item.stock}}</td>
                                    <td>{{item.dispensed_qty}}</td>
                                    <td>{{item.dispensed_by}}</td>
                                    <td>{{item.dispensed_at}}</td>
                                    
                                </tr>
                                
                                
                            </tbody>
                            
                        </table>

                    </div>
                </div>
            </ng-container>
            

        </div>

    </div>

</div>


<!-- modals -->

<div class="modal fade" id="modalProcessRequestItem" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{selectedItem.item}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <p>Requested qty: {{selectedItem.qty}}</p>
            <p>
                <textarea [(ngModel)]="remarks" class="form-control" placeholder="Enter remarks if any"></textarea>
            </p>
            <table class="table table-bordered">
                <thead>
                    <td>Stock</td>
                    <td>Remaining qty</td>
                    <td>Selected qty</td>
                </thead>

                <tbody>
                    <tr *ngFor="let row of selectedStocks; let i = index">
                        <td> 
                            <input [attr.data-index]="i" type="text" class="select-stock form-control" [(ngModel)]="row.stockName"/> 
                            <a *ngIf="row.stockName.length > 0" href="javascript:void(0);" data-dismiss="modal" routerLink="/dashboard/orders/new/{{row.stockName}}">Place new order</a>
                        </td>

                        <td> <input type="text" disabled class="form-control" [(ngModel)]="row.remainingQty"/> </td>
                        <td> <input type="text" class="form-control" [(ngModel)]="row.selectedQty"/> </td>

                    </tr>
                    <tr class="text-right">
                        <td colspan="3">
                            <button class="btn btn-outline-dark" (click)="addSelectedStock()">+</button>
                        </td>
                    </tr>
                </tbody>

            </table>

            
            

        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!dispensing" class="btn btn-primary" (click)="dispenseStock()">Save</button>
          <span class="spinner" *ngIf="dispensing">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>


<div class="modal fade" id="modalPhoto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{selectedItem.item}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

            <ng-container *ngIf="selectedItem.photo_url != null">
                <p class="text-center img-loader" *ngIf="loadingPhoto">
                    <i class="fa-solid fa-spinner fa-spin"></i>
                </p>
    
                <p class="text-center" *ngIf="!loadingPhoto">
                    <img [src]="imgUrl" class="img-fluid"/>
                </p>

                

            </ng-container>
            

            

        </div>
        
      </div>
    </div>
</div>