import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-return-requests',
  templateUrl: './return-requests.component.html',
  styleUrls: ['./return-requests.component.scss']
})
export class ReturnRequestsComponent implements OnInit {

  constructor(private dataService: DataService) { }
  data:any = {list:[], count:0};
  search:any = "";
  offset:any = 0;
  loading:any = false;
  limit:any = 20;
  date:any = "";
  statusFilter:any = 0;

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.dataService.getReturnRequests(this.offset, this.date, this.statusFilter, this.search, this.limit).subscribe( (data: any )=> {
      this.data = data;
      console.log(data);
    } );
  }

  filterByStatus(val){
    this.statusFilter = val;
    //this.dataService.localData.statusFilter = this.statusFilter;
    this.loadData();
  }

  searchByUser(data){
    this.search = data.search;
    //this.dataService.localData.searchRequestedUser = this.search;
    this.offset = 0;
    this.loadData();
  }

  clearSearch(){
    this.search = "";
    //this.dataService.localData.searchRequestedUser = this.search;
    this.loadData();
  }

  acceptReturn(item){
    let cnf = confirm("Accept return of stocks?");

    if(cnf){
      let data = {
        id:item.id
      }
      item.loading = true;
      this.dataService.postApproveStockReturn(data).subscribe( (res:any) => {
        item.loading = false;
  
        if(res.status == 0) {
          this.loadData();
  
          
        } else {
          alert(res.msg);
        }
  
      });

    }
  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadData();

  }

}
