import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-low-stock',
  templateUrl: './low-stock.component.html',
  styleUrls: ['./low-stock.component.scss']
})
export class LowStockComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }
  data:any = {list:[]};
  search:any = "";
  offset:any = 0;
  loading:any = false;
  limit:any = 30;
  allSelected:any = 0;
  vendor:any = "";
  path:any = "";

  newOrderBody:any = [
    {
      item:"",
      qty:1,
      unit_price:"",
      notes:""
    }
  ]

  ngOnInit(): void {
    this.path = this.dataService.REST_API_SERVER;
    this.loadData();
  }

  loadData(){
    this.dataService.getInvLowStock(this.search, this.offset, this.limit).subscribe( (data: any )=> {
      this.data = data;
      
    } );
  }

  selectOrder(item){
    item.selected = true;
    this.checkSelectionStatus();
  }
  unselectOrder(item){
    item.selected = false;
    this.checkSelectionStatus();
  }

  setSelection(val){
    this.allSelected = val;

    if(val == 1) {
      this.data.list.forEach(order => {
        this.selectOrder(order);
      });
    } else if(val == 0){
      this.data.list.forEach(order => {
        this.unselectOrder(order);
      });
    }

  }

  checkSelectionStatus(){
    let status = 0;
    let checked = 0;

    this.data.list.forEach(item => {
      if(item.selected){
        checked++;
      }
    });

    
    if(checked == this.data.list.length){
      this.allSelected = 1;
    } else if(checked == 0){
      this.allSelected = 0;
    } else {
      this.allSelected = 2;
    }

  }

  createOrder(){

    let orderBody = [];

    //Iterate through items;
    this.data.list.forEach(item => {
      if(item.selected){
        orderBody.push({
          item: item.stock,
          qty: item.qty,
          unit_price: item.unit_price,
          notes:""
        })
      }
    });

    if(orderBody.length > 0){
      this.newOrderBody = orderBody;
    }

    

  }

  submitOrder(){
    let data = {vendor:this.vendor, body:this.newOrderBody} ;

    this.loading = true;
    this.dataService.postInvOrderBody(data).subscribe( (res:any) => {
      this.loading = false;

      if(res.status == 0) {
        //this.loadData();
        //@ts-ignore
        $("#modalNew").modal("hide");

        this.router.navigate(['/dashboard/orders']);

        /*this.newOrderBody = [
          {
            item:"",
            qty:1,
            unit_price:"",
            notes:""
          }
        ];*/

        
      } else {
        alert(res.msg);
      }

    });

  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadData();

  }

  downloadReport(){
    let key = localStorage.getItem("key");
    let url = this.path + "reports/low_stock&main=0&&key="+key;
    window.open(url);
  }

}
