import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  //public REST_API_SERVER = "http://localhost/nipmr-inventory-api/frontend/web/?r="; //development
  public REST_API_SERVER = "https://nipmr-inventory-api.metanoa.ai/?r="; //production
  private SERVER_MAIN = "https://api.metanoa.ai/?r="; //main-app


  public localData = {
    statusFilter: 2,
    searchRequestedUser:"",
    requestDateFilter: "",
  };

  constructor(private httpClient: HttpClient) { }

  public login(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/login", data);
  }
  public headBar() {
    return this.httpClient.get(this.REST_API_SERVER + "account/headbar", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getInventory(search, offset) {
    return this.httpClient.get(this.REST_API_SERVER + "inventory/list&search="+search+"&offset="+offset, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postNewStock(data) {
    return this.httpClient.post(this.REST_API_SERVER + "inventory/add", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditStock(data) {
    return this.httpClient.post(this.REST_API_SERVER + "inventory/edit", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteStock(data) {
    return this.httpClient.post(this.REST_API_SERVER + "inventory/delete", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getReturnRequests(offset, date, statusFilter, search, limit) {
    return this.httpClient.get(this.REST_API_SERVER + "requests/return_requests&offset="+offset+"&date="+date+"&status="+statusFilter+"&search="+search+"&limit="+limit, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getRequests(offset, date, statusFilter, search) {
    return this.httpClient.get(this.REST_API_SERVER + "requests/list&offset="+offset+"&date="+date+"&status="+statusFilter+"&search="+search, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getRequestSingle(id) {
    return this.httpClient.get(this.REST_API_SERVER + "requests/view&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getRequestDispenses(id) {
    return this.httpClient.get(this.REST_API_SERVER + "requests/dispenses&request_id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getAssets(offset, search) {
    return this.httpClient.get(this.REST_API_SERVER + "assets/list&offset="+offset+"&search="+search, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getAssetLocations(id) {
    return this.httpClient.get(this.REST_API_SERVER + "assets/locations&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }
  public postAssetLocation(data) {
    return this.httpClient.post(this.REST_API_SERVER + "assets/new_location", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getAssetPhoto(fileKey) {
    return this.httpClient.get(this.REST_API_SERVER + "assets/get_file_url&fileKey="+fileKey, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getInventoryAc(term) {
    return this.httpClient.get(this.REST_API_SERVER + "inventory/autocomplete&term="+term, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getInventoryDispenses(id) {
    return this.httpClient.get(this.REST_API_SERVER + "inventory/dispense_log&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDispenseStock(data) {
    return this.httpClient.post(this.REST_API_SERVER + "requests/dispense_row", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postAsset(data) {
    return this.httpClient.post(this.REST_API_SERVER + "assets/new", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditAsset(data) {
    return this.httpClient.post(this.REST_API_SERVER + "assets/edit", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteAsset(data) {
    return this.httpClient.post(this.REST_API_SERVER + "assets/delete", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getUsers() {
    return this.httpClient.get(this.REST_API_SERVER + "account/users", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postUser(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/new_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postEditUser(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/edit_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postDeleteUser(data) {
    return this.httpClient.post(this.REST_API_SERVER + "account/delete_user", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getOrders(offset, date, vendor) {
    return this.httpClient.get(this.REST_API_SERVER + "orders/list&offset="+offset+"&date="+date+"&vendor="+vendor, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }
  public getOrderVendors() {
    return this.httpClient.get(this.REST_API_SERVER + "orders/list_vendors", {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getOrder(id) {
    return this.httpClient.get(this.REST_API_SERVER + "orders/view&id="+id, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postOrderBody(data) {
    return this.httpClient.post(this.REST_API_SERVER + "orders/new", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postStockPhoto(data) {
    return this.httpClient.post(this.REST_API_SERVER + "inventory/upload_photo", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getRequestedItemDetails(name, code) {
    return this.httpClient.get(this.REST_API_SERVER + "inventory/requested_item_details&name="+name+"&code="+code, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getPreviousStockData(item) {
    return this.httpClient.get(this.REST_API_SERVER + "inventory/previous_stock_data&item="+item, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getItemWithCode(code) {
    return this.httpClient.get(this.REST_API_SERVER + "inventory/get_item_with_code&code="+code, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getStorekeeperRequests(offset, limit) {
    return this.httpClient.get(this.REST_API_SERVER + "requests/storekeeper&offset="+offset+"&limit="+limit, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postIssueStock(data) {
    return this.httpClient.post(this.REST_API_SERVER + "requests/issue_stock", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postApproveStockReturn(data) {
    return this.httpClient.post(this.REST_API_SERVER + "requests/accept_return", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public getInvLowStock(search, offset, limit) {
    return this.httpClient.get(this.REST_API_SERVER + "inventory/low_stock&search="+search+"&limit="+limit+"&offset="+offset+"&main="+0, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

  public postInvOrderBody(data) {
    return this.httpClient.post(this.REST_API_SERVER + "orders/new", data, {
      headers: { 'key': localStorage.getItem("key") }
    });
  }

}
