<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>
                    Orders ({{data.count}})

                    
                </h3>

                
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-3 position-relative">
                    <select class="form-control" (change)="filterByVendor($event.target.value)">
                        <option></option>
                        <option *ngFor="let v of vendors">{{v}}</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <button class="btn btn-outline-dark" *ngIf="vendorFilter != null && vendorFilter != ''" (click)="print()">Print</button>
                </div>
                <div class="col-md-2 text-right">
                    <!-- <button class="btn btn-outline-dark" data-target="#modalNew" data-toggle="modal">Add new</button> -->
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td *ngIf="vendorFilter != null && vendorFilter != ''">
                        <i *ngIf="allSelected == 0" class="fa-regular fa-square" (click)="setSelection(1)"></i>
                        <i *ngIf="allSelected == 1" class="fa-regular fa-square-check" (click)="setSelection(0)"></i>
                        <i *ngIf="allSelected == 2" class="fa-solid fa-square" (click)="setSelection(1)"></i>
                    </td>
                    <td>Sl No.</td>
                    <td>Vendor</td>
                    <td>Items </td>
                    <td>Ordered by</td>
                    <td>Ordered at</td>
                    <td></td>
                </thead>
                <tbody>

                    <tr *ngFor="let row of data.list; let i = index">
                        <td *ngIf="vendorFilter != null && vendorFilter != ''">
                            <i *ngIf="!row.selected" class="fa-regular fa-square" (click)="selectOrder(row)"></i>
                            <i *ngIf="row.selected" class="fa-regular fa-square-check" (click)="unselectOrder(row)"></i>
                        </td>
                        <td>{{i+1}}</td>
                        <td>{{row.vendor}}</td>
                        <td>{{row.contents}}</td>
                        <td>{{row.created_by}}</td>
                        <td>{{row.created_at}}</td>
                        <td>
                            <a href="#modalView" data-toggle="modal" (click)="viewOrder(row)">View order</a>
                        </td>
                    </tr>
                    
                </tbody>
                
            </table>
            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>

            
            

        </div>

    </div>

</div>


<!-- modals -->

<!-- new-->
<div class="modal fade" id="modalNew" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add new order</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
        <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-2">
                <label>Vendor</label>
            </div>
            <div class="col-md-4">
                <input type="text" class="form-control" [(ngModel)]="vendor" name="vendor"/>
            </div>
        </div>
              
        <table class="table table-bordered">
            <thead>
                <td>Item</td>
                <td>Qty</td>
                <td>Notes</td>
            </thead>

            <tbody>
                <tr *ngFor="let row of newOrderBody; let i = index">
                    
                    <td> <input type="text" class="form-control" [(ngModel)]="row.item"/> </td>
                    <td> <input type="number" class="form-control" [(ngModel)]="row.qty"/> </td>
                    <td> <input type="text" class="form-control" [(ngModel)]="row.notes"/> </td>

                </tr>
                <tr class="text-right">
                    <td colspan="4">
                        <button class="btn btn-outline-dark" (click)="addOrderRow()">+</button>
                    </td>
                </tr>
            </tbody>

        </table>

          
        </div>
        <div class="modal-footer">
            
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button *ngIf="!loading" class="btn btn-primary" (click)="submitOrder()">Place order</button>
          <span class="spinner" *ngIf="loading">
            <i class="fa-solid fa-spinner fa-spin"></i>
          </span>
        </div>
      </div>
    </div>
</div>

<!-- view -->

<div class="modal fade" id="modalView" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">View order (Vendor: {{selectedOrderVendor}})</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
              
        <table class="table table-bordered">
            <thead>
                <td>Item</td>
                <td>Qty</td>
                <td>Unit price</td>
                <td>Notes</td>
            </thead>

            <tbody *ngIf="selectedOrderBody.length > 0">
                <tr *ngFor="let row of selectedOrderBody; let i = index">
                    <td>{{row.item}}</td>
                    <td>{{row.quantity}}</td>
                    <td>{{row.unit_price}}</td>
                    <td>{{row.notes}}</td>
                </tr>
                
            </tbody>
            
            

        </table>

          
        </div>
        
      </div>
    </div>
</div>