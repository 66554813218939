import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-dispense-log',
  templateUrl: './dispense-log.component.html',
  styleUrls: ['./dispense-log.component.scss']
})
export class DispenseLogComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  data:any = {stock:"", list:[]};
  id:any = 0;
  
  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.id = this.route.snapshot.paramMap.get('id');
    this.dataService.getInventoryDispenses(this.id).subscribe( (data: any )=> {
      this.data = data;
      
    } );
  }

}
