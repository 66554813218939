import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

  constructor(private dataService: DataService) { }
  
  role_id:any = 0;
  data:any = {list:[]};
  search:any = "";
  offset:any = 0;
  loading:any = false;
  uploading:any = false;
  loadingPhoto:any = false;
  limit:any = 30;
  selectedStock:any = {};
  imgUrl:any = null;
  uploadFile:any;
  uploadSize:any = "";
  newItem:any = {
    stock:"",
    common_name:"",
    vendor:"",
    brand:"",
    specification:"",
    unit:"",
    unit_price:"",
    balance:0

  }

  //calc
  newQty:any = 0;
  newUp:any = 0;
  newTp:any = 0;

  ngOnInit(): void {
    this.role_id = localStorage.getItem('role_id');
    this.loadData();
  }

  loadData(){
    this.dataService.getInventory(this.search, this.offset).subscribe( (data: any )=> {
      this.data = data;
      
    } );
  }

  clearSearch(){
    this.search = "";
    this.loadData();
  }
  submitStock(data){
    
    this.loading = true;
    this.dataService.postNewStock(data).subscribe( (res:any) => {
      this.loading = false;

      if(res.status == 0) {
        //@ts-ignore
        $("#modalAdd").modal("hide");
        this.loadData();

      } else {
        alert(res.msg);
      }

    });
  }

  searchStock(data){
    this.search = data.search;
    this.offset = 0;
    this.loadData();
  }

  navPage(dir){
    
    if(dir == "next"){
      this.offset += this.limit;
    } else {

      if(this.offset - this.limit < 0){
        this.offset = 0;
      } else {
        this.offset -= this.limit;
      }

    }

    this.loadData();

  }

  selectStock(stock){
    this.selectedStock = stock;
  }

  editStock(){
    this.loading = true;
    this.dataService.postEditStock(this.selectedStock).subscribe( (res:any) => {
      this.loading = false;

      if(res.status == 0) {
        //@ts-ignore
        $("#modalEdit").modal("hide");
        //this.loadData();

      } else {
        alert(res.msg);
      }

    });
  }

  deleteStock(stock){
    var cnf = confirm("Do you really want to delete " + stock.stock + "?");

    if(cnf){
      let data = {id:stock.id}
      this.dataService.postDeleteStock(data).subscribe( (res:any) => {
        this.loading = false;
  
        if(res.status == 0) {
          this.loadData();
  
        } else {
          alert(res.msg);
        }
  
      });
    }

  }

  calcTp(){
    this.newTp = this.newQty * this.newUp;
    
  }

  viewPhoto(stock){
    this.selectedStock = stock;
    
    if(stock.photo_url != null){

      this.loadingPhoto = true;
      this.dataService.getAssetPhoto(stock.photo_url).subscribe( (data: any )=> {
        this.loadingPhoto = false;
  
        if(data.status == 0){
          this.imgUrl= data.url;
          console.log(this.imgUrl);
        }
        
      } );

    } else {
      //
    }

  }

  onFileSelect(e){
    this.uploadFile = e.target.files[0];

    let uploadSize = "";
    if(this.uploadFile.size > 1000 && this.uploadFile.size < 1000000 ){
      uploadSize = (this.uploadFile.size / 1000).toFixed(1) + " KB";

    } else if(this.uploadFile.size >= 1000000){
      uploadSize = (this.uploadFile.size / 1000000).toFixed(1) + " MB";
    }
     else {
      uploadSize = this.uploadFile.size + " Bytes";
    }

    this.uploadSize = uploadSize;

  }

  uploadPhoto(){
    

    if(typeof this.uploadFile !== undefined && this.uploadFile != null){
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.uploadFile, this.uploadFile.name);
      formData.append("stock_id", this.selectedStock.id);

      if(this.uploadFile.size < 500000){
        this.dataService.postStockPhoto(formData).subscribe( (res:any = {status:0, msg:""}) => {
          this.uploading = false;
          if(res.status == 0) {
            
            //@ts-ignore
            this.selectedStock.photo_url = res.filename;
            this.viewPhoto(this.selectedStock);
            this.uploadFile =  null;
            
            var input = document.getElementById('fileInput');
            input.setAttribute("type", "text");
            input.setAttribute("type", "file");
            
          } else {
            alert(res.msg);
          }
        
        });
      } else {
        this.uploading = false;
        alert("You can't upload files greater than 500KB in size");
      }

      

    }

    

  }

  fetchItemWithCode(val){
    
    this.dataService.getItemWithCode(val).subscribe( (data: any )=> {
      if(data.status == 0){
        this.newItem = data.item;
        this.newUp = data.item.unit_price;
        console.log(this.newItem);
      }
    } );

  }

}
