<div class="page" style="">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Inventory > Low Stock ({{data.count}})</h3>
            </div>
        </div>
  
        <div class="page-body">
  
            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-4 position-relative">
                    <!--<form #fsearch="ngForm" (ngSubmit)="searchStock(fsearch.value)" id="formSearch" ngNativeValidate>
                        <input type="text" class="form-control" placeholder="Type and press enter to search" [(ngModel)]="search" name="search"/>
                        <span *ngIf="search != ''" class="btn-clear-search" (click)="clearSearch()"> &times; </span>
                    </form> -->
                </div>
                <div class="col-md-6">
  
                </div>
                <div class="col-md-2 text-right">
                    <button class="btn btn-outline-dark" (click)="downloadReport()">Download</button>
                </div>
            </div>
  
            <table class="table table-bordered">
              <thead>
                  <td>
                    <i *ngIf="allSelected == 0" class="fa-regular fa-square" (click)="setSelection(1)"></i>
                    <i *ngIf="allSelected == 1" class="fa-regular fa-square-check" (click)="setSelection(0)"></i>
                    <i *ngIf="allSelected == 2" class="fa-solid fa-square" (click)="setSelection(1)"></i>
                  </td>
                  <td>Item name</td>
                  <td>Common name</td>
                  <td>Vendor</td>
                  <td>Qty</td>
                  <td>Unit of measurement</td>
                  <td>Unit price</td>
                  <td>Total price</td>
                  <td>Dispensed</td>
                  <td>Balance</td>
                  
              </thead>
              <tbody>
                  <tr *ngFor="let stock of data.list">
                      <td>
                          <i *ngIf="!stock.selected" class="fa-regular fa-square" (click)="selectOrder(stock)"></i>
                          <i *ngIf="stock.selected" class="fa-regular fa-square-check" (click)="unselectOrder(stock)"></i>
                      </td>
                      <td>{{stock.stock}}</td>
                      <td>{{stock.common_name}}</td>
                      <td>{{stock.company}}</td>
                      <td>{{stock.qty}}</td>
                      <td>{{stock.unit}}</td>
                      <td>{{stock.unit_price}}</td>
                      <td>{{stock.total_price}}</td>
                      <td>{{stock.dispensed}}</td>
                      <td>{{stock.balance}}</td> 
                      
                      
                      
                  </tr>
              </tbody>
              
            </table>
  
            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div>
  
        </div>
  
    </div>
  
  </div>
  
  
  <div class="modal fade" id="modalNew" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add new order</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            
          <div class="row" style="margin-bottom: 20px;">
              <div class="col-md-2">
                  <label>Vendor</label>
              </div>
              <div class="col-md-4">
                  <input type="text" class="form-control" [(ngModel)]="vendor" name="vendor"/>
              </div>
          </div>
                
          <table class="table table-bordered">
              <thead>
                  <td>Item</td>
                  <td>Qty</td>
                  <td>Unit price</td>
                  <td>Notes</td>
              </thead>
    
              <tbody>
                  <tr *ngFor="let row of newOrderBody; let i = index">
                      
                      <td> <input type="text" [attr.data-index]="i" class="form-control select-stock" [(ngModel)]="row.item"/> </td>
                      <td> <input type="number" class="form-control" [(ngModel)]="row.qty"/> </td>
                      <td> <input type="text" class="form-control" [(ngModel)]="row.unit_price"/> </td>
                      <td> <input type="text" class="form-control" [(ngModel)]="row.notes"/> </td>
    
                  </tr>
                  <!--<tr class="text-right">
                      <td colspan="4">
                          <button class="btn btn-outline-dark" (click)="addOrderRow()">+</button>
                      </td>
                  </tr>-->
              </tbody>
    
          </table>
    
            
          </div>
          <div class="modal-footer">
              
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button *ngIf="!loading" class="btn btn-primary" (click)="submitOrder()">Place order</button>
            <span class="spinner" *ngIf="loading">
              <i class="fa-solid fa-spinner fa-spin"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  
  
  
  