import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit {

  constructor(private dataService: DataService, private router:Router, private route: ActivatedRoute) { }
  item:any = "";
  newOrderBody:any = [
    {
      item:"",
      qty:1,
      vendor:"",
      notes:""
    }
  ]
  loading:any = false;
  previousData:any = [];

  ngOnInit(): void {
    this.item = this.route.snapshot.paramMap.get('item');
    if( this.item != null && this.item.length > 0){
      this.newOrderBody[0].item = this.item;
      //Fetch previous data;
      
      this.fetchPreviousStockData(this.item);

    }
  }

  addOrderRow(){
    this.newOrderBody.push({
      item:"",
      qty:1,
      vendor:"",
      notes:""
    });
  }

  submitOrder(){
    let data = this.newOrderBody;

    this.loading = true;
    this.dataService.postOrderBody(data).subscribe( (res:any) => {
      this.loading = false;

      if(res.status == 0) {
        //redirect to orders page;
        this.router.navigate(['/dashboard/orders']);

      } else {
        alert(res.msg);
      }

    });

  }

  fetchPreviousStockData(item){
    
    this.dataService.getPreviousStockData(item).subscribe( (data: any )=> {
      if(data.status == 0){
        this.previousData = data.list;
      }
      
    } );
  }

  selectVendor(data){
    this.newOrderBody[0].vendor = data.vendor;
  }

}
