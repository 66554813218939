import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }

  id:any = 0;
  request:any = {};
  dispenses:any = {list:[]};
  selectedItem:any = {}
  loading:any = false;
  dispensing:any = false;
  selectedStocks:any = [
    {
      stockId:0,
      stockName:"",
      remainingQty:0,
      selectedQty:0
    }
  ];
  date:any = "";
  loadingPhoto:any = false;
  imgUrl:any = null;
  remarks:any = "";

  ngOnInit(): void {

    this.id = this.route.snapshot.paramMap.get('id');

    this.loadRequest();
    this.loadRequestDispenses();

    let context = this

    //Autocomplete
    //@ts-ignore
    $('body').on('focus', '.select-stock', function(){
      //@ts-ignore
      let moi = $(this);
      
      moi.autocomplete({
        appendTo: '#modalProcessRequestItem',
				source: function(request, response){
					context.dataService.getInventoryAc(request.term).subscribe( (data: any[] ) =>{
						var array = data.map(function(element){

              

							return {
                value: element['stock'], 
                obj: {
                  stockId: element['id'],
                  stockName: element['stock'],
                  remainingQty: element['balance'],
                  selectedQty: (element['balance'] >= context.selectedItem.qty) ? context.selectedItem.qty : element['balance'],
                }
              };
						  });
						response(array);
					});
				},
        delay:500,
				select: function(event, ui) {
				
          context.selectedStocks[moi.attr("data-index")] = ui.item.obj;
          
          //@ts-ignore
          $(".select-stock").blur();
					return false;
				}
			});

    })

  }

  loadRequest(){
    this.dataService.getRequestSingle(this.id).subscribe( (data: any )=> {
      this.request = data;
      
    } );
  }

  loadRequestDispenses(){
    this.dataService.getRequestDispenses(this.id).subscribe( (data: any )=> {
      this.dispenses = data;
      console.log(data);
      
    } );
  }

  selectItem(item){
    this.selectedItem = item;
    
    //feth item details
    this.dataService.getRequestedItemDetails(item.item, item.code).subscribe( (data: any )=> {
      
      if(data.status == 0){
        if(data.item.remainingQty >= item.qty){
          data.item.selectedQty = item.qty
        } else {
          data.item.selectedQty = data.item.remainingQty
        }
        this.selectedStocks[0] = data.item;
        
      }

      
      
    } );


  }

  addSelectedStock(){
    this.selectedStocks.push({
      stockId:0,
      stockName:"",
      remainingQty:0,
      selectedQty:0
    });
  }

  dispenseStock(){

    let data = {
      request_id: this.id,
      request_body_id: this.selectedItem.id,
      selectedStocks: this.selectedStocks,
      remarks:this.remarks

    };
    this.dispensing = true;
    this.dataService.postDispenseStock(data).subscribe( (res:any) => {
      this.dispensing = false;

      if(res.status == 0) {
        this.loadRequest();

        this.selectedStocks = [
          {
            stockId:0,
            stockName:"",
            remainingQty:0,
            selectedQty:0
          }
        ];

        //@ts-ignore
        $("#modalProcessRequestItem").modal("hide");
      } else {
        alert(res.msg);
      }

    });
    

  }

  viewPhoto(item){
    this.selectedItem = item;
    if(item.photo_url != null){

      this.loadingPhoto = true;
      this.dataService.getAssetPhoto(item.photo_url).subscribe( (data: any )=> {
        this.loadingPhoto = false;
  
        if(data.status == 0){
          this.imgUrl= data.url;
          
        }
        
      } );

    } else {
      //
    }
  }

  

  
  


}
