<div class="page">

    <div class="container-fluid">
        <div class="row page-header">
            <div class="col-md-12">
                <h3>Dispense log for {{data.stock}}</h3>
            </div>
        </div>

        <div class="page-body">

            <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-4">
                    
                </div>
                <div class="col-md-6">

                </div>
                <div class="col-md-2 text-right">
                    
                </div>
            </div>

            <table class="table table-bordered">
                <thead>
                    <td>Dispensed by</td>
                    <td>Dispensed at</td>
                    <td>Dispensed Qty</td>
                    <td>Requested By</td>
                    <td></td>
                    
                </thead>
                <tbody>
                    <tr *ngFor="let item of data.list">
                        <td>{{item.dispensed_by}}</td>
                        <td>{{item.dispensed_at}}</td>
                        <td>{{item.qty}}</td>
                        <td>{{item.requested_by}}</td>
                        <td>
                            <a href="javascript:void(0);" routerLink="/dashboard/requests/{{item.request_id}}">  View request </a>
                        </td>
                    </tr>
                </tbody>
                
            </table>

            <!--
            <div class="row">
                <div class="col-md-6">
                    <ng-container *ngIf="offset > 0">
                        <img src="assets/img/icon-arrow-left-active.svg" class="btn-nav" (click)="navPage('prev')"/>
                    </ng-container>
                    
                </div>
                <div class="col-md-6 text-right">
                    <ng-container *ngIf="offset < data.count - offset && data.count > limit">
                        <img src="assets/img/icon-arrow-right-active.svg" class="btn-nav" (click)="navPage('next')"/>
                    </ng-container>
                </div>
            </div> -->

        </div>

    </div>

</div>


