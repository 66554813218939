import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule }from'@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InventoryComponent } from './dashboard/inventory/inventory.component';
import { RequestsComponent } from './dashboard/requests/requests.component';
import { RequestComponent } from './dashboard/request/request.component';
import { AssetsComponent } from './dashboard/assets/assets.component';
import { DispenseLogComponent } from './dashboard/dispense-log/dispense-log.component';
import { UsersComponent } from './dashboard/users/users.component';
import { OrdersComponent } from './dashboard/orders/orders.component';
import { ReportComponent } from './dashboard/report/report.component';
import { NewOrderComponent } from './dashboard/new-order/new-order.component';
import { StoreRequestsComponent } from './dashboard/store-requests/store-requests.component';
import { ReturnRequestsComponent } from './dashboard/return-requests/return-requests.component';
import { LowStockComponent } from './dashboard/low-stock/low-stock.component';


const routes: Routes = [
  {
    path: '', component:LoginComponent
  },
  {
    path: 'login', component:LoginComponent
  },
  {
    path: 'dashboard', component:DashboardComponent,
    children: [
      {
        path:'inventory', component: InventoryComponent
      },
      {
        path:'low-stock', component:LowStockComponent
      },
      {
        path:'dispense-log/:id', component: DispenseLogComponent
      },
      {
        path:'store-requests', component: StoreRequestsComponent
      },
      {
        path:'requests', component: RequestsComponent
      },
      {
        path:'requests/:id', component: RequestComponent
      },
      {
        path:'return-requests', component: ReturnRequestsComponent
      },
      {
        path:'assets', component: AssetsComponent
      },
      {
        path:'users', component: UsersComponent
      },
      {
        path:'orders', component: OrdersComponent
      },
      {
        path:'orders/new', component: NewOrderComponent
      },
      {
        path:'orders/new/:item', component: NewOrderComponent
      },
      {
        path:'reports', component: ReportComponent
      },
    ]
  },
  
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    InventoryComponent,
    RequestsComponent,
    RequestComponent,
    AssetsComponent,
    DispenseLogComponent,
    UsersComponent,
    OrdersComponent,
    ReportComponent,
    NewOrderComponent,
    StoreRequestsComponent,
    ReturnRequestsComponent,
    LowStockComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    
  ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
